.title {
    font-weight: 700;
    font-size: 21px;
}
.fade-text {
  position: relative;
}

.fade-text::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50px; /* Adjust the height for the desired fade size */
  background: linear-gradient(transparent, white);
  pointer-events: none;
  z-index: 1;
}

/* .fade-text::before {
  top: 0;
  left: 0;
} */

.fade-text::after {
  bottom: 0;
  left: 0;
}

.toggle_content a {
    text-decoration: none;
}
.showMore {
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,.15)!important;
}


.discHeight {
  height: 100px;
}
