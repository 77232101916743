.exam_logo_div img {
  /* margin-top: -60px; */
}
.exam_page_header {
  /* background: linear-gradient(to top, #212159 0%, #003399 100%); */
}
.exam_page_header .exam_full_name {
  font-size: 30px;
  font-weight: 500;
}

.nav-link {
  color: #212159 !important;
}
.exam_page_body .nav .active {
  color: #dc3545 !important;
  border-bottom: 3px solid #dc3545;
}
.exam_page_body .title {
  font-weight: 700;
}

.exam_page_body .nav .nav-link {
  font-weight: 500;
  padding: 8px !important;
  font-size: 14px !important;
}
.exam_page_body tbody,
.exam_page_body td,
.exam_page_body tfoot,
.exam_page_body th,
.exam_page_body thead,
.exam_page_body tr {
  border-width: 2px;
}
.exam_page_body .campus_view,
.exam_page_body .new_updates {
  border: 2px solid #dee2e6;
}
.campus_view .view_all {
  float: right;
  cursor: pointer;
}
.campus_view .view_all:hover {
  color: #212159;
}
.exam_page_body .all_program small {
  font-size: 11px;
}

/* On screens that are 992px or less */
@media screen and (max-width: 992px) {
}

/* On screens that are 600px or less */
@media screen and (max-width: 600px) {
}
