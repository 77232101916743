.filter_page p {
  margin-bottom: 0px;
}
.filter_page small {
  font-size: 12px;
}
.filter_page tr > td {
  padding-left: 10px;
}
.filter_page label {
  text-transform: capitalize;
}

header {
  font-family: "Lobster", cursive;
  text-align: center;
  font-size: 25px;
}

#info {
  font-size: 18px;
  color: #555;
  text-align: center;
  margin-bottom: 25px;
}

a {
  color: #074e8c;
}

.filter_page .scrollbar {
  height: 135px;
  overflow-y: scroll;
}

.filter_scrollbar {
  font-size: 14px;
}

/*
 *  STYLE 1
 */

.filter_page .filter_scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.filter_page .filter_scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.filter_page .filter_scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #212159;
}

.college-info-card {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}
.college-info-card:hover {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.4);
}
.college-info-card .college-name:hover {
  text-decoration: underline;
}

.college-info-card table tr td:first-child {
  padding: 0px;
}