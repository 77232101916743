.main_navbar .show_submenu_data {
  display: block;
}
.main_navbar a {
  color: unset;
}

.bg-submenu {
  background-color: #f5f5f5 !important;
  border-left: 4px solid #d2d1d1;
}
.main_navbar .dropdown-menu ul {
  list-style: none;
}
.main_navbar .menu_submenu li {
  background-color: white;
}
/* .main_navbar .mega-menu li:hover {
	background-color: #F5F5F5;
} */
.main_navbar .submenu_data {
  background-color: #f5f5f5;
}
.main_navbar .submenu_data a {
  font-size: 14px;
  padding-bottom: 5px;
}
.main_navbar .submenu_data a:hover {
  color: #212159;
  font-weight: 500;
  text-decoration: underline;
}

.main_navbar .ktm-mega-menu ul li:not(:first-child) {
  font-size: small;
}

/* .search_result .search_slugs {
	text-transform: capitalize;
} */

.searchScrollbar {
  height: 153px;
  overflow-y: scroll;
}
.searchScrollbar li:hover {
	background-color: #f7f5f5;;
}

.search_scrollbar::-webkit-scrollbar-track {
	border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.search_scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.search_scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #212159;
}

@media all and (min-width: 992px) {
  .dropdown-menu {
    width: 13rem;
  }
  .mega-submenu {
    left: 100%;
    top: 0;
    min-width: 25rem;
  }
  .ktm-mega-menu {
    position: static !important;
  }
  .mega-menu {
    left: 0;
    right: 0;
    width: 100%;
  }
  .dropdown-menu li {
    position: relative;
  }
  .dropdown-menu .submenu {
    display: none;
    left: 100%;
    top: 0;
  }
  .dropdown-menu > li:hover > .submenu,
  .dropdown:hover > .dropdown-menu {
    display: block;
  }
}
