.featured_colleges_section .college_img_div {
    width: 20%;
}

.featured_colleges_section .college_name_div .college_name {
    font-size: 16px;
}

.college_name_div .college_location {
    font-size: 12px;
}

.featured_college .featured-college-div {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);

}
.featured_college:hover .featured-college-div {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.3);
}
.featured_college .featured-college-div .college_name:hover {
    font-weight: 500;
}

@media only screen and (max-width: 768px) {
    .college_name_div .college_name {
        font-size: 12px;
    }
    .college_name_div .college_location {
        font-size: 10px;
    }
  }