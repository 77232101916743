@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:wght@100;400;500;700&display=swap");
/* <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:wght@100;400;500;700&display=swap" rel="stylesheet"> */
body{
  font-family: "roboto" !important;
}
.main {
  background-color: aliceblue;
  font-size: 14px;
}
a,
a:link {
  text-decoration: none;
  cursor: pointer;
}
ul {
  padding-left: 0px !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.flc {
  text-transform: capitalizenp;
}
.bg-theme {
  background: #262163;
}
.bg-light-theme {
  background: #f7f5f5;
}
.text-theme {
  color: #262163;
}
.bg-white {
  background-color: #fff;
}
.bg-danger {
  background: #e31e24 !important;
}
.w-80 {
  width: 80%;
}
.flc {
  text-transform: capitalize;
}

.form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(21 21 59 / 25%) !important;
}
.form-check-input:checked {
  background-color: #262163 !important;
  border-color: #262163 !important;
}

body {
  /* overflow-y: hidden; Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
