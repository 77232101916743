.filter_section .filtered_column > div {
    background: #fff;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}
.filter_section .filtered_column > div:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.4);
}
.filter_section .filtered_column > div a {
    color: #111;
}.filter_section .filtered_column > div a:hover {
    /* background: #212159; */
    color: #212159;
}