
.hero_section {
    /* font-size: 30px; */
}

.hero_card {
    background: #212159;
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}
.hero_image_text {
    position: relative;
}
.hero_image_text div {
    border-radius: 10px 50px;
}
.hero_image_text .pOne {
    background: #212159;
    color: white;
    width: fit-content;
    /* z-index: 10; */
    position: absolute;
    top: 30px;
    right: -130px;
}
.divOne img, .divTwo img {
    border-top-right-radius: 70px;
    border-bottom-left-radius: 70px;
}
.divTwo {
    margin-top: 100px;
}
.hero_image_text .pTwo {
    background: #212159;
    color: white;
    width: fit-content;
    /* z-index: 10; */
    position: absolute;
    bottom: 10px;
    left: -130px;
}