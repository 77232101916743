.registerForm {
  color: blue;
}
#LeadGenPopup .form-label {
    margin-bottom: 0.3rem !important;
}
#LeadGenPopup .form-control {
    margin-bottom: 0.5rem ;
}
#LeadGenPopup .modal-dialog {
  max-width: 900px;
}
@media only screen and (max-width: 768px) {
  #LeadGenPopup .modal-dialog {
    max-width: 90vw;
  }
}
@media only screen and (max-width: 480px) {
  #LeadGenPopup .modal-dialog {
    max-width: 100vw;
  }
}
